<template>
  <v-container grid-list-lg fluid>
    <List />
  </v-container>
</template>

<script>
import List from "../components/List";

export default {
  components: {
    List
  }
};
</script>

<style scoped></style>
