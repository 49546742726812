export default {
  namespaced: true,
  state: {
    editInvoiceId: null,
    trackingNumber: null,
    selectedCustomer: null,
    selectedUser: null,
    Invoice: {
      date: null,
      dueDate: null,
      CustomerId: null,
      note: null
    },
    InvoiceDetails: [],
  },
  getters: {
    getEditInvoiceId(state) {
      return state.editInvoiceId
    },
    getInvoiceDueDate(state) {
      return state.Invoice.dueDate
    },
    getInvoiceDate(state) {
      return state.Invoice.date
    },
    getInvoiceNote(state) {
      return state.Invoice.note
    },
    getTrackingNumber(state) {
      return state.trackingNumber
    },
    getSelectedCustomer(state) {
      return state.selectedCustomer
    },
    getSelectedUser(state) {
      return state.selectedUser
    },
    Invoice(state) {
      return state.Invoice
    },
    invoiceTotal(state) {
      let total = 0;
      state.InvoiceDetails.map(row => {

        if (!row.price) row.price = 0
        if (!row.qty) row.qty = 1

        if (!row.delete) {
          // if (row.unitCountInBox && row.unitCountInBox > 0) {
          //   total += (parseFloat(row.qty) * parseFloat(row.unitCountInBox)) * parseFloat(row.price);
          // } else {
            total += parseFloat(row.qty) * parseFloat(row.price);
          // }

        }

      });
      return total;
    },
    totalBoxes(state) {
      let total = 0;
      if (state.InvoiceDetails) {
        state.InvoiceDetails.map(row => {
          // console.log('row', row)
          if (!row.delete) total += Math.abs(parseFloat(row.boxQty));
        });
      }
      return total;
    },
    totalQty(state) {
      let total = 0;
      if (state.InvoiceDetails) {
        state.InvoiceDetails.map(row => {
          if (!row.delete) total += Math.abs(parseFloat(row.qty));
        });
      }
      return total;
    },
    InvoiceDetails(state) {
      return state.InvoiceDetails
    }
  },
  actions: {
    CopyPriceToAll({
      commit
    }, index) {
      commit('COPY_PRICE_TO_ALL', index)
    },
    SetEditInvoiceId({
      commit
    }, payload) {
      commit('SET_EDIT_INVOICE_ID', payload)
    },
    loadInvoice({
      commit
    }, payload) {
      commit('LOAD_INVOICE', payload)
    },
    ResetInvoice({
      commit
    }) {
      commit('RESET_INVOICE')
    },
    SetInvoiceDate({
      commit
    }, payload) {
      commit('SET_INVOICE_DATE', payload)
    },
    SetInvoiceDueDate({
      commit
    }, payload) {
      commit('SET_INVOICE_DUE_DATE', payload)
    },
    SetInvoiceNote({
      commit
    }, payload) {
      commit('SET_INVOICE_NOTE', payload)
    },
    SetTrackingNumber({
      commit
    }, payload) {
      commit('SET_TRACKING_NUMBER', payload)
    },
    SetSelectedCustomer({
      commit
    }, payload) {
      commit('SET_SELECTED_CUSTOMER', payload)
    },
    SetSelectedUser({
      commit
    }, payload) {
      commit('SET_SELECTED_USER', payload)
    },
    DeleteInvoiceDetailRow({
      commit
    }, payload) {
      commit('DELETE_INVOICE_DETAIL_ROW', payload)
    },
    UnDeleteInvoiceDetailRow({
      commit
    }, payload) {
      commit('UN_DELETE_INVOICE_DETAIL_ROW', payload)
    },
    AddInvoiceDetail({
      commit
    }, payload) {
      commit('ADD_INVOICE_DETAIL', payload)
    }
  },
  mutations: {
    async COPY_PRICE_TO_ALL(state, index) {
      const sourcePriceRow = state.InvoiceDetails[index]
      console.log('sourcePriceRow', sourcePriceRow)
      await Promise.all(
        state.InvoiceDetails.map(row => {
          // // console.log('change price', row.ProductId,  sourcePriceRow.ProductId)
          if (row.ProductId === sourcePriceRow.ProductId) {
            row.price = sourcePriceRow.price;
            row.total = sourcePriceRow.price * sourcePriceRow.qty;
          }
        })
      );
    },
    RESET_INVOICE(state) {
      state.Invoice = {
        date: null,
        dueDate: null,
        CustomerId: null,
        note: null
      }
      state.InvoiceDetails = []
      state.trackingNumber = null
      state.selectedCustomer = null
    },
    LOAD_INVOICE(state, payload) {
      state.Invoice.date = payload.date
      state.Invoice.localId = payload.localId
      state.Invoice.dueDate = payload.dueDate
      state.Invoice.CustomerId = payload.CustomerId
      state.Invoice.trackingNumber = payload.trackingNumber
      state.Invoice.note = payload.note
      state.InvoiceDetails = payload.InvoiceDetails
      state.InvoiceDetails.map(row => {
        row.total = row.qty * row.price
      })
      // state.selectedCustomer = payload.Customer
    },
    SET_EDIT_INVOICE_ID(state, payload) {
      state.editInvoiceId = payload
    },
    SET_INVOICE_DATE(state, payload) {
      state.Invoice.date = payload
    },
    SET_INVOICE_DUE_DATE(state, payload) {
      state.Invoice.dueDate = payload
    },
    SET_INVOICE_NOTE(state, payload) {
      state.Invoice.note = payload
    },
    SET_TRACKING_NUMBER(state, payload) {
      state.trackingNumber = payload
    },
    SET_SELECTED_CUSTOMER(state, payload) {
      state.selectedCustomer = payload
      state.Invoice.CustomerId = payload.id
    },
    SET_SELECTED_USER(state, payload) {
      state.selectedUser = payload
      state.Invoice.SalesRepUserId = payload.id
    },
    DELETE_INVOICE_DETAIL_ROW(state, payload) {
      const temp = state.InvoiceDetails[payload]
      temp.delete = true
      state.InvoiceDetails.splice(payload, 1, temp)
      // state.InvoiceDetails.splice(payload, 1);
    },
    UN_DELETE_INVOICE_DETAIL_ROW(state, payload) {
      const temp = state.InvoiceDetails[payload]
      // // console.log('temp', temp)
      delete temp.delete
      // temp.delete = true
      state.InvoiceDetails.splice(payload, 1, temp)
      // state.InvoiceDetails.splice(payload, 1);
    },
    ADD_INVOICE_DETAIL(state, payload) {
      state.InvoiceDetails.unshift(payload)
    }
  },
}