<template>
  <v-list-item v-if="checkRightStatus(15)" @click="deleteCustomer(Customer)">
    <v-list-item-title>{{ $t("buttons.delete") }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import customerService from "../service.js";

export default {
  props: ["Customer"],
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
  },
  methods: {
    deleteCustomer() {
      if (!this.checkRightStatus(39) && this.Customer.balance !== 0) {
        this.$swal(
          "Delete Customer",
          "You are not allow to delete customer with balance.",
          "warning"
        );
        return false;
      }

      if (this.currentBusiness.metadata && this.currentBusiness.metadata.deletePrecaution) {
        this.$swal.fire({
          title: "Are you sure?",
          html: `You won't be able to revert this.</br> <div style="color:red;">Type <span style="font-weight: bold">delete</span> in order remove this</div>`,
          icon: "question",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.value && result.value === 'delete') {
            customerService
              .delete(this.Customer.id)
              .then((response) => {
                if (response.data) {
                  this.$swal("Deleted!", "Customer has been deleted.", "success");
                  this.$emit("submit");
                }
              })
              .catch((error) => {
                throw error;
              });
          } else if (result.dismiss === 'cancel') {

          } else {
            this.$swal("Delete", 'Type "delete" to delete item', "error");
          }
        })
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            customerService
              .delete(this.Customer.id)
              .then((response) => {
                if (response.data) {
                  this.$swal("Deleted!", "Customer has been deleted.", "success");
                  this.$emit("submit");
                }
              })
              .catch((error) => {
                throw error;
              });
          }
        });
      }


    },
  },
};
</script>

<style scoped>

</style>
