import customerService from '../Customer/service'

export default {
  namespaced: true,
  state: {
    Customer: null,
  },
  getters: {
    getCustomer(state) {
      return state.Customer
    },
  },
  actions: {
    setCustomer({
      commit
    }, id) {
      return customerService.getById(id)
        .then(response => {
          // // console.log('response.data.alert', response)
          // if (response.data && !response.data.alert) response.data.alert = false

          commit('SETCUSTOMER', response.data)
        })

    },
  },
  mutations: {
    SETCUSTOMER(state, payload) {
      
      if (payload) {
        document.title = `smplsale - ${payload.name}`
        state.Customer = payload
      }
    },
  },
}