<template>
  <v-container v-if="getCustomer" class="pa-0" grid-list-lg fluid>
    <v-alert v-if="getCustomer" border="left" type="error" tile transition="slide-y-transition" dismissible class="mb-2"
      v-model="getCustomer.alert" color="red">
      <strong>{{ getCustomer.note }}</strong>
    </v-alert>
    <Edit v-if="getCustomer" :editDialog="editDialog" @close="editDialog = false" @submit="loadData"
      :editCustomer="getCustomer"></Edit>
    <v-card tile v-if="getCustomer">
      <v-toolbar dense flat color="grey lighten-3">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title class="text-capitalize">
          {{ getCustomer.name }}
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-button">
            <div v-if="checkRightStatus(6)">
              <v-list-item v-if="$vuetify.breakpoint.xsOnly" @click="newInvoice">
                <v-list-item-title>New Invoice</v-list-item-title>
              </v-list-item>
            </div>
            <v-list-item v-if="checkRightStatus(23)" @click="editDialog = !editDialog">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <DeleteCustomer :Customer="getCustomer" @submit="$router.go(-1)" />
            <v-list-item @click="viewOpenInvoice()">
              <v-list-item-title>View Open Invoice</v-list-item-title>
            </v-list-item>
            <SyncBalance />
            <Statement :CustomerId="getCustomer.id" />
            <v-divider></v-divider>
            <RemoteOrderLink :id="getCustomer.id" :customer="getCustomer" />
            <v-divider
              v-if="this.currentBusiness.metadata && this.currentBusiness.metadata.serializeProduct"></v-divider>
            <SearilSearch v-if="this.currentBusiness.metadata && this.currentBusiness.metadata.serializeProduct" />
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout class="mt-5" v-if="!getCustomer" align-center justify-center column fill-height>
          <v-progress-circular size="70" width="7" color="purple" indeterminate="indeterminate"></v-progress-circular>
          <div>Loading...</div>
        </v-layout>
        <v-layout row wrap v-else justify-space-between>
          <v-flex shrink>
            <div class="text-capitalize">
              <span class="font-weight-medium">Contact Name: </span>
              <span v-if="getCustomer.contactName">{{
                getCustomer.contactName
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Phone Number: </span>
              <span v-if="getCustomer.phoneNumber">{{
                getCustomer.phoneNumber | USPhoneNumber
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Cell Number: </span>
              <span v-if="getCustomer.cellNumber">{{
                getCustomer.cellNumber | USPhoneNumber
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div>
              <span class="font-weight-medium">Email: </span>
              <a v-if="getCustomer.email" @click="sendEmail(getCustomer.email)">{{ getCustomer.email }}</a>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Tax ID: </span>
              <span v-if="getCustomer.taxId">{{ getCustomer.taxId }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Customer Terms: </span>
              <span v-if="getCustomer.metadata && getCustomer.metadata.invoiceDue && getCustomer.metadata.invoiceDue.name">{{ getCustomer.metadata.invoiceDue.name }}</span>
              <span v-else>Not Set</span>
            </div>
            <div>
                <span class="font-weight-medium">Customer Since: </span>
                <span>{{ getCustomer.createdAt | moment("from", "now") }}</span>
              </div>
            <span v-if="!$vuetify.breakpoint.xsOnly">
              <v-btn class="mt-2" color="info" v-if="checkRightStatus(6)" @click="newInvoice">New Invoice</v-btn>
            </span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink>
            <div class="text-right">
              <div v-if="getCustomer.address">
                <div class="font-weight-medium">Billing Address</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a v-on="on" @click="openGoogleMap">
                      <div class="text-capitalize title">
                        {{ getCustomer.address }}
                      </div>
                      <div class="text-capitalize">
                        {{ getCustomer.city }}, {{ getCustomer.state }}
                        {{ getCustomer.zip }}
                      </div>
                    </a>
                  </template>
                  <span>Click to open google map</span>
                </v-tooltip>
              </div>



              <div v-if="getCustomer.shippingAddress">
                <div class="font-weight-medium">Shipping Address</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a v-on="on" @click="openGoogleMap">
                      <div class="text-capitalize title">
                        {{ getCustomer.shippingAddress }}
                      </div>
                      <div class="text-capitalize">
                        {{ getCustomer.shippingCity }}, {{ getCustomer.shippingState }}
                        {{ getCustomer.shippingZip }}
                      </div>
                    </a>
                  </template>
                  <span>Click to open google map</span>
                </v-tooltip>
              </div>

              

              <div class="mt-3" v-if="checkRightStatus(35)">
                <div class="text-capitalize title">Balance</div>
                <div :style="{ color: balanceColor }" class="text-capitalize font-weight-light display-3">
                  {{ getCustomer.balance | currency }}
                </div>
                <div>Limit: {{ getCustomer.balanceLimit | currency }}</div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-tabs v-model="active" slider-color="#F3C500" color="black" show-arrows grow>
      <v-tab>{{ $t("labels.dashboard") }}</v-tab>
      <v-tab>Custom Price</v-tab>
      <v-tab>Statistics</v-tab>
      <v-tab>Order</v-tab>
      <v-tab>Open Invoice</v-tab>
      <v-tab>Payments</v-tab>
      <v-tab>All Invoice</v-tab>
      <v-tab>Attachment</v-tab>
    </v-tabs>
    <v-tabs-items background-color="transparent" v-model="active" touchless="touchless">
      <v-tab-item>
        <Dashboard> </Dashboard>
      </v-tab-item>
      <v-tab-item>
        <CustomPrice :CustomerId="getCustomer.id"> </CustomPrice>
      </v-tab-item>
      <v-tab-item>
        <Statistics> </Statistics>
      </v-tab-item>
      <v-tab-item>
        <OrderList v-if="getCustomer" :CustomerId="getCustomer.id"> </OrderList>
      </v-tab-item>
      <v-tab-item>
        <InvoiceList :due="true" :showAll="true" v-if="getCustomer" :CustomerId="getCustomer.id">
        </InvoiceList>
      </v-tab-item>
      <v-tab-item>
        <PaymentList v-if="getCustomer" :CustomerId="getCustomer.id">
        </PaymentList>
      </v-tab-item>
      <v-tab-item>
        <InvoiceList v-if="getCustomer" :CustomerId="getCustomer.id">
        </InvoiceList>
      </v-tab-item>
      <v-tab-item>
        <AttachmentList v-if="getCustomer" TypeId="2" :RelationId="getCustomer.id">
        </AttachmentList>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
const Dashboard = () => import("../components/Dashboard");
const CustomPrice = () =>
  import("@/modules/Customer/CustomerPrice/components/List.vue");
const Statistics = () => import("../components/Statistics");
const InvoiceList = () => import("@/modules/Invoice/components/List");
const AttachmentList = () => import("@/modules/Attachment/components/List");
const OrderList = () => import("@/modules/Order/InHouse/components/List");
const PaymentList = () => import("../Payment/components/List");
const DeleteCustomer = () => import("../components/DeleteCustomer");
const RemoteOrderLink = () => import("@/components/RemoteOrderLink");
const SyncBalance = () =>
  import("@/modules/Customer/components/syncBalance.vue");
const Statement = () => import("@/modules/Customer/components/Statement");
const SearilSearch = () =>
  import("@/modules/Customer/components/ImeiSearch.vue");

import { mapGetters, mapActions } from "vuex";

import Hashids from "hashids";
const hashids = new Hashids();

const Edit = () => import("@/modules/Customer/components/Edit");

export default {
  name: "customer-profile",
  components: {
    Edit,
    RemoteOrderLink,
    SyncBalance,
    Dashboard,
    CustomPrice,
    Statement,
    InvoiceList,
    AttachmentList,
    PaymentList,
    OrderList,
    DeleteCustomer,
    Statistics,
    SearilSearch,
  },
  data() {
    return {
      fab: false,
      active: null,
      editDialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to && from && to.name === from.name) {
        this.loadData();
      }
    },
  },
  created() {
    this.loadData();
    this.$events.listen("customerProfileEvent", () => this.loadData());
  },
  beforeDestroy() {
    this.$events.remove("customerProfileEvent");
  },
  computed: {
    ...mapGetters("customer", ["getCustomer"]),
    ...mapGetters("global", ["checkRightStatus", "currentBusiness"]),
    balanceColor() {
      let color = "black";
      if (this.getCustomer.balance > this.getCustomer.balanceLimit) {
        color = "red";
      } else {
        color = "black";
      }

      return color;
    },
  },
  methods: {
    ...mapActions("customer", ["setCustomer"]),
    ...mapActions("global", ["setPageTitle"]),

    viewOpenInvoice() {
      const hashId = hashids.encode(this.getCustomer.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/openInvoice`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    newInvoice() {
      this.$router.push({
        path: `/invoice/create?CustomerId=${this.getCustomer.id}`,
      });
    },
    async loadData() {
      let id = this.$route.params.id || this.getCustomer.id;
      // console.log("test", id);
      this.$Progress.start();

      await this.setCustomer(id);
      // this.setPageTitle(this.getCustomer.name);
      this.$Progress.finish();
    },
    openGoogleMap() {
      window.open(
        `https://www.google.com/maps/place/${this.getCustomer.address} ${this.getCustomer.city},${this.getCustomer.state} ${this.getCustomer.zip}`,
        "_blank"
      );
    },
    sendEmail() {
      let routeData =
        "https://mail.google.com/mail/?view=cm&fs=1&to=" +
        this.getCustomer.email;
      window.open(routeData, "newwindow", "width=800, height=950");
    },
    closeEdit() {
      this.editDialog = false;
    },
    updated() {
      this.closeEdit();
      this.loadData();
    },
  },
};
</script>
