<template>
  <v-container grid-list-lg class="pa-0" fluid>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <v-card :loading="loading" outlined>
      <ListTitle :access="checkRightStatus(7)" :title="`${customers.length} Customer`" @add="add" />
      <v-card-title>
        <v-flex xs12>
          <v-text-field v-model="search" label="Search" outlined autofocus prepend-inner-icon="search" rounded dense
            single-line hide-details clearable></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text class="touch-view-container">
        <v-data-table :headers="headers" :items="customers" :search="search" :loading="loading" :sortDesc="sortDesc"
          @current-items="updateTotal" :sort-by="sortBy" :page.sync="page" hide-default-footer
          :itemsPerPage="customers.length">
          <template v-slot:item="{ item }">
            <tr class="pointer" @click="viewProfile(item)">
              <td class="text-left">
                <div v-html="$highlight(item.name, search.toLowerCase())
                  ">
                  {{ item.name }}
                </div>
                <div v-html="$highlight(
                  item.cellNumber,
                  search.toLowerCase()
                )
                  ">
                  {{ item.cellNumber }}
                </div>
              </td>
              <td></td>
              <td class="text-right">
                <span v-if="checkRightStatus(35)">{{
                  item.balance | currency
                  }}</span>
              </td>
            </tr>
          </template>
          <!-- <template v-slot:body.append="{ headers }">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td
                :colspan="headers.length - 2"
                class="text-right font-weight-bold"
              >
                List Total
              </td>

              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
             
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td
                :colspan="headers.length - 2"
                class="text-right font-weight-bold"
              >
                Result Total
              </td>

              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
             
            </tr>
          </template> -->
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import customerService from "../service.js";
// import DeleteCustomer from "../components/DeleteCustomer";

const Create = () => import("./Create");
export default {
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedCustomer: null,
      total: 0,
      fullTotal: 0,
      headers: [
        {
          text: "Business Name",
          value: "name",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "cellNumber",
          sortable: false,
          align: "left",
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "left",
          sortable: true,
        },
      ],
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
      search: "",
      refundDialog: false,
      selectedTask: null,
      customers: [],
      loading: true,
    };
  },
  async created() {
    this.getList();
    this.$events.listen("CustomerMiniList", () => this.getList());
  },
  beforeDestroy() {
    this.$events.remove("CustomerMiniList");
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  components: {
    Create,
    // DeleteCustomer,
  },
  watch: {
    search(val) {
      if (!val) {
        this.search = "";
      }
    },
  },
  methods: {
    ...mapActions("customer", ["setCustomer"]),
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.balance;
      });
    },
    viewProfile(val) {
      this.setCustomer(val.id);
      // this.$router.push({ path: `/customer/${val.id}/profile` });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      let where = {};
      if(!this.currentUser.isAdmin && this.checkRightStatus(50)){
        where.SalesRepUserId = this.currentUser.id
      }
      return customerService
        .getAll(where)
        .then((response) => {
          // console.log('mini reload run', response)
          this.customers = response.data;
          this.fullTotal = 0;
          this.customers.map((row) => {
            this.fullTotal += row.balance;
          });
          this.loading = false;
          return response;
        })
        .catch((error) => {
          console.log('error', error)
          throw error;
        });
    },
    editCustomer(customer) {
      this.selectedCustomer = customer;
      this.editDialog = true;
    },
    deleteCustomer(customer) {
      if (!this.checkRightStatus(39)) {
        this.$swal(
          "Delete Customer",
          "You are not allow to delete customer with balance.",
          "warning"
        );
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          customerService
            .delete(customer.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Customer has been deleted.", "success");
                this.getList();
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.touch-view-container {
  height: calc(100vh - 300px);
  width: 100%;
  overflow: auto;
}
</style>
