<template>
  <v-container grid-list-lg fluid>
    <DeletedList />
  </v-container>
</template>

<script>
import DeletedList from "../components/DeletedList";

export default {
  components: {
    DeletedList
  },
  created() {
    document.title = "smplsale - Deleted Customer";
  }
};
</script>

<style scoped></style>
