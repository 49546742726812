<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Customer" :title="`Balance Sheet`" />

        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table
                  dense
                  :headers="headers"
                  :itemsPerPage="Customer.length"
                  hide-default-footer
                  :items="Customer"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">
                        <a href="#" @click="viewOpenInvoice(item)">{{
                          item.name
                        }}</a>
                      </td>
                      <td class="text-right" :style="{ color: color(item) }">
                        {{ item.balance | currency }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Customer.length > 0"
                  >
                    <tr>
                      <td
                        :colspan="headers.length - 3"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import customerList from "@/modules/Customer/service";
import { mapGetters, mapActions } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      showEmailBtn: false,
      isLoading: true,
      Customer: null,
      headers: [
        {
          text: "CUSTOMER NAME",
          align: "left",
          value: "name",
          sortable: false,
        },

        {
          text: "BALANCE",
          align: "right",
          value: "balance",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    total() {
      let total = 0;
      this.Customer.map((row) => {
        total += row.balance;
      });

      return total;
    },
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    color(item) {
      let color = "black";
      if (item.balance < 0) {
        color = "red";
      }
      return color;
    },
    viewOpenInvoice(item) {
      const hashId = hashids.encode(item.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/openInvoice`,
      });
      window.open(routeData.href, "_blank");
      // window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadData() {
      return customerList
        .getAll({ balanceSheet: true, hideZero: true })
        .then((response) => {
          this.$Progress.finish();
          this.Customer = response.data;
          document.title = `Report - Balance Sheet`;

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
