<template>
  <div>
    <Create :createDialog="createDialog" @close="createDialog = false"></Create>

    <v-toolbar dense flat>

      <v-toolbar-title>Customer</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        v-if="checkRightStatus(7)"
        text
        dark
        @click="add"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">New</span>
        <v-icon v-else>add</v-icon>
      </v-btn>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        text
        dark
        to="/customer/list"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">List</span>
        <v-icon v-else>list</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <router-view></router-view>
  </div>
</template>
<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import customerStore from "./store.js";
import invoiceStore from "@/modules/Invoice/store.js";

import { mapGetters } from "vuex";

const Create = () => import("./components/Create");
export default {
  name: "customer-index",
  mixins: [RegisterStoreModule],
  created() {
    this.registerStoreModule("customer", customerStore);
    this.registerStoreModule("invoice", invoiceStore);
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"])
  },
  data() {
    return {
      createDialog: false
    };
  },
  components: {
    Create
  },
  methods: {
    add() {
      this.createDialog = true;
    }
  }
};
</script>
