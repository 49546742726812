<template>
  <v-container grid-list-lg class="pa-0" fluid>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = false" @submit="getList" :editCustomer="selectedCustomer">
    </Edit>
    <v-card :loading="loading" outlined>
      <ListTitle :access="checkRightStatus(7)" title="Customer" @add="add" />
      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" label="Search" outlined prepend-inner-icon="search" rounded dense single-line
            hide-details clearable></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="customers" :search="search" :loading="loading" :sortDesc="sortDesc"
          @current-items="updateTotal" :sort-by="sortBy" :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left" @click="viewProfile(item)" v-html="$highlight(item.name, search.toLowerCase())">
                {{ item.name }}
              </td>

              <td class="text-left" @click="viewProfile(item)"
                v-html="$highlight(item.contactName, search.toLowerCase())">
                {{ item.contactName }}
              </td>
              <td class="text-left" @click="viewProfile(item)"
                v-html="$highlight(item.cellNumber, search.toLowerCase())">
                {{ item.cellNumber }}
              </td>
              <td class="text-left" @click="viewProfile(item)" v-html="$highlight(item.address, search.toLowerCase())">
                {{ item.address }}
              </td>
              <td class="text-left" @click="viewProfile(item)" v-html="$highlight(item.city, search.toLowerCase())">
                {{ item.city }}
              </td>
              <td class="text-left" @click="viewProfile(item)" v-html="$highlight(item.state, search.toLowerCase())">
                {{ item.state }}
              </td>
              <td class="text-right" @click="viewProfile(item)">
                <span v-if="checkRightStatus(33)">{{ item.balance | currency }}</span>
              </td>

              <td class="text-right" @click="viewProfile(item)">
                <span v-if="checkRightStatus(33)">{{ item.balanceLimit | currency }}</span>
              </td>

              <td class="text-right" @click="viewProfile(item)">
                <span v-if="checkRightStatus(33)">{{ (item.balanceLimit-item.balance) | currency }}</span>
              </td>

              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-if="checkRightStatus(23)" @click="editCustomer(item)">
                      <v-list-item-title>{{
                        $t("buttons.edit")
                      }}</v-list-item-title>
                    </v-list-item>
                    <DeleteCustomer :Customer="item" @submit="getList()" />
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ headers }">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                List Total
              </td>

              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                Result Total
              </td>

              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td></td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import customerService from "../service.js";
import DeleteCustomer from "../components/DeleteCustomer";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedCustomer: null,
      total: 0,
      fullTotal: 0,
      headers: [
        {
          text: "Business Name",
          value: "name",
          sortable: true,
          align: "left",
        },
        {
          text: "Contact Name",
          value: "contactName",
          align: "left",
          sortable: true,
        },
        {
          text: "Cell Number",
          value: "cellNumber",
          align: "left",
          sortable: true,
        },
        {
          text: "Address",
          value: "address",
          align: "left",
          sortable: true,
        },
        {
          text: "City",
          value: "city",
          align: "left",
          sortable: true,
        },
        {
          text: "State",
          value: "state",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "left",
          sortable: true,
        },
        {
          text: "Limit",
          value: "balanceLimit",
          align: "left",
          sortable: true,
        },
        {
          text: "Diff",
          value: "(balanceLimit-balance)",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
      search: '',
      refundDialog: false,
      selectedTask: null,
      customers: [],
      loading: true,
    };
  },
  async created() {
    this.getList();
    this.$events.listen("customerListEvent", () => this.getList());

    // this.reset();
  },
  beforeDestroy() {
    this.$events.remove("customerListEvent");
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  watch: {
    search(val) {
      if (!val) {
        this.search = ''
      }
    }
  },
  components: {
    Create,
    Edit,
    DeleteCustomer,
  },
  methods: {
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.balance;
      });
    },
    viewProfile(val) {
      this.$router.push({ path: `/customer/${val.id}/profile` });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      let where = {};
      if(!this.currentUser.isAdmin && this.checkRightStatus(50)){
        where.SalesRepUserId = this.currentUser.id
      }
      return customerService
        .getAll(where)
        .then((response) => {
          // console.log('customers response', response)
          this.customers = response.data;
          this.fullTotal = 0;
          this.customers.map((row) => {
            this.fullTotal += row.balance;
          });
          this.loading = false;
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
    editCustomer(customer) {
      this.selectedCustomer = customer;
      this.editDialog = true;
    },
    deleteCustomer(customer) {
      if (!this.checkRightStatus(39)) {
        this.$swal(
          "Delete Customer",
          "You are not allow to delete customer with balance.",
          "warning"
        );
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          customerService
            .delete(customer.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Customer has been deleted.", "success");
                this.getList();
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
