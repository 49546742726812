<template>
  <v-container grid-list-lg fluid>
    <div v-if="!$vuetify.breakpoint.xsOnly">
      <splitpanes class="default-theme">
        <pane min-size="30" size="35">
          <CustomerMiniList  />
        </pane>
        <pane>
          <div v-if="!getCustomer">
            <lottie
              :options="defaultOptions"
              v-on:animCreated="handleAnimation"
            />
            <div class="text-center">No customer selected</div>
          </div>

          <div v-else>
            <Profile />
          </div>
        </pane>
      </splitpanes>
      <!-- <v-row>
        <v-col md="4">
          <CustomerMiniList />
        </v-col>
        <v-col md="8">
          <div v-if="!getCustomer">
            <lottie
              :options="defaultOptions"
              v-on:animCreated="handleAnimation"
            />
            <div class="text-center">No customer selected</div>
          </div>

          <div v-else>
            <Profile />
          </div>
        </v-col>
      </v-row> -->

      <!-- <v-row v-if="currentUser.isAdmin">
        <v-col sm="12" md="6">
          <CustomerCount />
        </v-col>
        <v-col sm="12" md="6">
          <OutstandingMoney />
        </v-col>
      </v-row> -->
    </div>

    <v-row v-else>
      <v-col cols="12">
        <CustomerList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import OutstandingMoney from "../components/Tile/OutstandingMoney";
// import CustomerCount from "../components/Tile/Count";
import CustomerList from "../components/List";
import CustomerMiniList from "../components/MiniList";
import Lottie from "@/components/lottie.vue";
import * as animationData from "@/assets/lottie/empty_list.json";
import Profile from "./Profile";
// import TopCustomer from '../Chart/TopCustomer'
import { mapGetters } from "vuex";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  name: "customer-home",
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
    };
  },
  computed: {
    ...mapGetters("customer", ["getCustomer"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
  components: {
    // OutstandingMoney,
    // CustomerCount,
    CustomerList,
    CustomerMiniList,
    Profile,
    Splitpanes,
    Pane,
    Lottie,
    // TopCustomer,
  },
};
</script>

<style scoped></style>
