var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"grid-list-lg":"","fluid":""}},[_c('Create',{attrs:{"createDialog":_vm.createDialog},on:{"close":function($event){_vm.createDialog = false},"submit":_vm.getList}}),_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('ListTitle',{attrs:{"access":_vm.checkRightStatus(7),"title":((_vm.customers.length) + " Customer")},on:{"add":_vm.add}}),_c('v-card-title',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Search","outlined":"","autofocus":"","prepend-inner-icon":"search","rounded":"","dense":"","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',{staticClass:"touch-view-container"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"loading":_vm.loading,"sortDesc":_vm.sortDesc,"sort-by":_vm.sortBy,"page":_vm.page,"hide-default-footer":"","itemsPerPage":_vm.customers.length},on:{"current-items":_vm.updateTotal,"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"pointer",on:{"click":function($event){return _vm.viewProfile(item)}}},[_c('td',{staticClass:"text-left"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$highlight(item.name, _vm.search.toLowerCase())
                )}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$highlight(
                item.cellNumber,
                _vm.search.toLowerCase()
              )
                )}},[_vm._v(" "+_vm._s(item.cellNumber)+" ")])]),_c('td'),_c('td',{staticClass:"text-right"},[(_vm.checkRightStatus(35))?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.balance)))]):_vm._e()])])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }