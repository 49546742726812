<template>
  <v-container grid-list-lg class="pa-0" fluid>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        :access="false"
        title="Deleted Customer"
      />
      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customers"
          :search="search"
          :loading="loading"
          :sort-by="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left">{{ item.name }}</td>
              <td class="text-left">
                {{ item.contactName }}
              </td>
              <td class="text-left">
                {{ item.cellNumber }}
              </td>
              <td class="text-left">
                {{ item.deletedAt | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left">
                <span v-if="checkRightStatus(33)">{{
                  item.balance | currency
                }}</span>
              </td>

              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item
                      v-if="checkRightStatus(23)"
                      @click="unDeletedCustomer(item)"
                    >
                      <v-list-item-title>Un-Deleted</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import customerService from "../service.js";
// import DeleteCustomer from "../components/DeleteCustomer";

export default {
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedCustomer: null,
      headers: [
        {
          text: "Business Name",
          value: "name",
          sortable: true,
          align: "left",
        },
        {
          text: "Contact Name",
          value: "contactName",
          align: "left",
          sortable: true,
        },
        {
          text: "Cell Number",
          value: "cellNumber",
          align: "left",
          sortable: true,
        },
        {
          text: "Deleted At",
          value: "deletedAt",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "left",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      search: null,
      refundDialog: false,
      selectedTask: null,
      customers: [],
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  async created() {
    this.getList();
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    getList() {
      return customerService
        .getAll({ deleted: true })
        .then((response) => {
          this.customers = response.data;
          this.loading = false;
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
    unDeletedCustomer(customer) {
      if (!this.checkRightStatus(39)) {
        this.$swal(
          "Delete Customer",
          "You are not allow to delete customer with balance.",
          "warning"
        );
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          customerService
            .recover(customer.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Un-Delete!", "Customer was recover.", "success");
                this.getList();
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
